import React, { useEffect, useRef, useState } from 'react';
import './MediaViewer.css';

const MediaViewer = ({ selectedMedia }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (selectedMedia?.type === 'video' && videoRef.current) {
            const videoUrl = `/media/${selectedMedia.path}`;
            videoRef.current.pause();
            videoRef.current.src = videoUrl;
            videoRef.current.load();
            videoRef.current.play().catch(err => {
                console.error('Video playback error:', err);
            });
        }
    }, [selectedMedia]);

    // Add quality parameter to image URLs
    const getImageUrl = (path) => {
        return `/media/${path}?q=70`;  // Request 70% quality
    };

    return (
        <div className="media-viewer">
            {selectedMedia ? (
                selectedMedia.type === 'video' ? (
                    <video 
                        ref={videoRef}
                        controls
                        playsInline
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    >
                        <source 
                            src={`/media/${selectedMedia.path}`}
                            type="video/mp4" 
                        />
                    </video>
                ) : (
                    <img 
                        src={getImageUrl(selectedMedia.path)}
                        alt={selectedMedia.name}
                        style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'contain'
                        }}
                        loading="eager"
                    />
                )
            ) : (
                <div className="placeholder">Select media to view</div>
            )}
        </div>
    );
};

export default MediaViewer;

