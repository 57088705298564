import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { FaPlay, FaList, FaCheckSquare, FaArrowLeft, FaArrowRight, FaChevronLeft, FaChevronRight, FaSort, FaTrash } from 'react-icons/fa';
import MediaViewer from './components/MediaViewer';
import ClientInstructions from './components/ClientInstructions';
import MediaGallery from './components/MediaGallery';

// Update the base URL constants
const API_BASE_URL = 'https://edit.snapped.cc';
const MEDIA_BASE_URL = '/media';  // Changed to be path-only

axios.defaults.baseURL = API_BASE_URL;

function App() {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [currentPath, setCurrentPath] = useState('');
  const [directories, setDirectories] = useState([]);
  const [directorySearch, setDirectorySearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isReordering, setIsReordering] = useState(false);

  const mediaGalleryRef = useRef(null);

  const fetchDirectories = async (path) => {
    try {
      // Log the attempt
      console.log('Fetching directories for path:', path);
      
      // Get the client name from the path
      const clientName = path.split('/')[0];
      
      const response = await axios.get(`/api/directories`, {
        params: {
          path: path // Pass the full path now
        }
      });
      
      setDirectories(response.data || []);
      console.log('Fetched directories:', response.data); // Debug log
    } catch (error) {
      console.error('Failed to fetch directories:', error);
      setDirectories([]);
    }
  };

  // Update directories when path changes
  useEffect(() => {
    if (currentPath) {
      fetchDirectories(currentPath);
    }
  }, [currentPath]);

  const handleClientSelect = async (clientName) => {
    console.log('Selected client:', clientName); // Debug log
    setCurrentPath(clientName);
    setDirectorySearch(''); // Clear the search after selection
  };

  const handleDirectoryClick = (dirName) => {
    const newPath = `${currentPath}/${dirName}`;
    setCurrentPath(newPath);
  };

  // Function to handle going back in the path
  const handlePathClick = (index) => {
    const pathParts = currentPath.split('/');
    const newPath = pathParts.slice(0, index + 1).join('/');
    setCurrentPath(newPath);
  };

  // Format the path for display
  const getPathParts = () => {
    if (!currentPath) return [];
    return currentPath.split('/');
  };

  const searchClients = async (query) => {
    try {
      if (query.length < 4) {
        setSearchResults([]);
        return;
      }
      const response = await axios.get(`/api/search-clients`, {
        params: { query }
      });
      console.log('Search results:', response.data); // Debug log
      setSearchResults(response.data);
    } catch (error) {
      console.error('Failed to search clients:', error);
      setSearchResults([]);
    }
  };

  const handleDirectorySearch = (e) => {
    const value = e.target.value;
    setDirectorySearch(value);
    searchClients(value);
  };

  // Filter directories based on search
  const filteredDirectories = directories.filter(dir => {
    console.log('Directory:', dir, 'Search:', directorySearch); // Debug log
    return dir.toLowerCase().includes(directorySearch.toLowerCase());
  });

  // Also log the directories state when it changes
  useEffect(() => {
    console.log('Directories updated:', directories); // Debug log
  }, [directories]);

  // Add these navigation functions
  const handlePrevious = () => {
    const pathParts = currentPath.split('/');
    if (pathParts.length > 1) {
      const newPath = pathParts.slice(0, -1).join('/');
      setCurrentPath(newPath);
    } else {
      setCurrentPath('');
    }
  };

  const handleNext = () => {
    if (directories.length > 0) {
      const newPath = `${currentPath}/${directories[0]}`;
      setCurrentPath(newPath);
    }
  };

  // Add handler for when reordering completes
  const handleReorderComplete = () => {
    setIsReordering(false);
  };

  const handleDelete = async () => {
    if (!selectedMedia) return;
    
    if (window.confirm('Are you sure you want to move this file to trash?')) {
      try {
        const response = await axios.post('/api/delete', {
          filePaths: [selectedMedia.path]
        });
        
        if (response.data && response.data[0].status === 'success') {
          // Clear selected media first
          setSelectedMedia(null);
          
          // Small delay to ensure backend changes are complete
          setTimeout(async () => {
            // Force complete refresh by clearing and resetting path
            const currentPathCopy = currentPath;
            setCurrentPath('');  // Clear path
            await fetchDirectories('');  // Fetch root
            
            // Then restore path to trigger full refresh
            setTimeout(async () => {
              setCurrentPath(currentPathCopy);
              await fetchDirectories(currentPathCopy);
              
              // Finally refresh MediaGallery
              if (mediaGalleryRef.current) {
                mediaGalleryRef.current.refreshFiles();
              }
            }, 100);
          }, 100);
        } else {
          console.error('Failed to delete file');
        }
      } catch (error) {
        console.error('Delete error:', error);
      }
    }
  };

  return (
    <div className="app-container">
      <div className="main-layout">
        <div className="media-viewer-container">
          {/* Client Search with dropdown results */}
          <div className="search-client">
            <input
              type="text"
              placeholder="Search for client..."
              value={directorySearch}
              onChange={handleDirectorySearch}
            />
            {/* Search Results Dropdown */}
            {directorySearch.length >= 4 && searchResults.length > 0 && (
              <div className="search-results-dropdown">
                {searchResults.map((dir, index) => (
                  <div 
                    key={index}
                    className="search-result-item"
                    onClick={() => handleClientSelect(dir)}
                  >
                    {dir}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Directory Path with clickable parts */}
          <div className="directory-path">
            <span onClick={() => setCurrentPath('')}>Root</span>
            {getPathParts().map((part, index) => (
              <span key={index}>
                {' / '}
                <span 
                  className="path-part"
                  onClick={() => handlePathClick(index)}
                >
                  {part}
                </span>
              </span>
            ))}
          </div>

          {/* Directory Listing - only show if there are directories */}
          {directories.length > 0 && (
            <div className="subdirectories">
              {directories.map((dir, index) => (
                <div 
                  key={index}
                  className="directory-item"
                  onClick={() => handleDirectoryClick(dir)}
                >
                  {dir}
                </div>
              ))}
            </div>
          )}

          {/* Control Panel */}
          <div className="control-panel">
            <div className="control-panel-buttons">
              <button 
                className="control-button"
                onClick={handlePrevious}
              >
                <FaChevronLeft />
              </button>
              <button 
                className="control-button"
                onClick={handleNext}
              >
                <FaChevronRight />
              </button>
              <button 
                className={`control-button ${isReordering ? 'active' : ''}`}
                onClick={() => setIsReordering(!isReordering)}
                title={isReordering ? "Stop Reordering" : "Start Reordering"}
              >
                <FaSort />
              </button>
              <button 
                className="control-button"
                onClick={handleDelete}
                title="Move to Trash"
                disabled={!selectedMedia}
              >
                <FaTrash />
              </button>
            </div>
          </div>

          {/* Media Viewer */}
          <MediaViewer selectedMedia={selectedMedia} />
        </div>

        {/* Right - Media Gallery (files only) */}
        <MediaGallery 
          ref={mediaGalleryRef}
          onSelect={setSelectedMedia}
          viewMode="grid"
          currentPath={currentPath}
          isReordering={isReordering}
          onReorderComplete={handleReorderComplete}
        />
      </div>
    </div>
  );
}

export default App;
