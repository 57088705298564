import React, { useState, useEffect, memo, useRef } from 'react';
import axios from 'axios';
import './MediaGallery.css';
import { FaPlay } from 'react-icons/fa';

const MediaGallery = ({ viewMode, currentPath, onSelect, isReordering }) => {
    const [mediaItems, setMediaItems] = useState([]);
    const [loadedImages, setLoadedImages] = useState(new Set());
    const [selectedItems, setSelectedItems] = useState([]);
    const [error, setError] = useState(null);
    const observerRef = useRef(null);
    const itemRefs = useRef({});
    const [draggedItem, setDraggedItem] = useState(null);

    const getFileType = (filename) => {
        const ext = filename.toLowerCase().split('.').pop();
        if (['mp4', 'mov', 'avi', 'MP4', 'MOV', 'AVI'].includes(ext)) {
            console.log('Detected video file:', filename);
            return 'video';
        }
        if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
            return 'image';
        }
        console.log('Unknown file type:', filename);
        return 'unknown';
    };

    const handleImageLoad = (itemId) => {
        setLoadedImages(prev => new Set([...prev, itemId]));
    };

    const fetchMediaItems = async (path) => {
        try {
            console.log('🟦 MediaGallery - Original path:', path);
            
            // Split path by forward slashes and encode each part separately
            const encodedPath = path.split('/')
                .map(part => encodeURIComponent(part))
                .join('/');
            
            console.log('🟦 MediaGallery - Encoded path:', encodedPath);
            
            const response = await axios.get(`/api/files`, {
                params: { path: encodedPath }
            });
            
            console.log('🟦 MediaGallery - Response:', response.data);
            
            if (!response.data) {
                console.warn('🟦 MediaGallery - No data received');
                setMediaItems([]);
                return;
            }
            
            // Sort the media items by their numeric prefix
            const sortedItems = response.data.sort((a, b) => {
                // Extract the numeric prefix from the filename (now handling 4-digit numbers)
                const getPrefix = (filename) => {
                    const match = filename.match(/^(\d{4})/);
                    return match ? parseInt(match[1], 10) : 0;
                };
                
                const prefixA = getPrefix(a.name);
                const prefixB = getPrefix(b.name);
                
                return prefixA - prefixB;
            });
            
            console.log('✅ MediaGallery - Files sorted:', sortedItems.map(item => item.name));
            setMediaItems(sortedItems);
            
        } catch (error) {
            console.error('❌ Failed to fetch media items:', error);
            setError('Failed to fetch media items');
        }
    };

    useEffect(() => {
        if (currentPath) {
            fetchMediaItems(currentPath);
        }
    }, [currentPath]);

    // Observe new items
    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const img = entry.target.querySelector('img');
                        if (img && img.dataset.src) {
                            img.src = img.dataset.src;
                            delete img.dataset.src;
                        }
                    }
                });
            },
            { rootMargin: '50px' }
        );

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    // Add reorder handler here
    const handleReorder = async () => {
        try {
            if (!currentPath) return;
            
            // Get current files in order
            const files = mediaItems.map(item => item.name);
            
            // Call reorder endpoint
            const response = await axios.post('/api/reorder-files', {
                path: currentPath,
                files: files
            });

            if (response.data.success) {
                // Refresh the media items
                await fetchMediaItems(currentPath);
                console.log('✅ Files reordered successfully');
            }
        } catch (error) {
            console.error('❌ Error reordering files:', error);
            setError('Failed to reorder files');
        }
    };

    const handleDragStart = (e, item) => {
        if (!isReordering) return;
        console.log('🟦 Drag started:', item.name);
        setDraggedItem(item);
        e.currentTarget.classList.add('dragging');
    };

    const handleDragEnd = (e) => {
        console.log('🟦 Drag ended');
        e.currentTarget.classList.remove('dragging');
        setDraggedItem(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!isReordering) return;
        
        // Add visual feedback for drop target
        const target = e.currentTarget;
        target.classList.add('drag-over');
    };

    const handleDragLeave = (e) => {
        // Remove visual feedback when leaving drop target
        e.currentTarget.classList.remove('drag-over');
    };

    const handleDrop = async (e, targetItem) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Remove visual feedback
        e.currentTarget.classList.remove('drag-over');
        
        if (!isReordering || !draggedItem || draggedItem.id === targetItem.id) return;

        console.log('🟦 Dropping', draggedItem.name, 'onto', targetItem.name);
        
        // Get the directory path from the full path
        const dirPath = draggedItem.path.substring(0, draggedItem.path.lastIndexOf('/'));
        console.log('🟦 Directory path:', dirPath);

        const updatedItems = [...mediaItems];
        const draggedIndex = mediaItems.findIndex(item => item.id === draggedItem.id);
        const targetIndex = mediaItems.findIndex(item => item.id === targetItem.id);

        console.log('🟦 Moving from index', draggedIndex, 'to', targetIndex);

        // Reorder the array
        updatedItems.splice(draggedIndex, 1);
        updatedItems.splice(targetIndex, 0, draggedItem);

        setMediaItems(updatedItems);
        
        try {
            const files = updatedItems.map(item => item.name);
            console.log('🟦 Sending reorder request:');
            console.log('Path:', dirPath);
            console.log('Files:', files);
            
            const response = await axios.post('/api/reorder-files', {
                path: dirPath,
                files: files
            });
            
            if (response.data.success) {
                console.log('✅ Reorder successful:', response.data);
                await fetchMediaItems(currentPath); // Refresh the list
            } else {
                console.error('❌ Reorder failed:', response.data);
                setError('Failed to reorder files');
            }
        } catch (error) {
            console.error('❌ Error during reorder:', error.response?.data || error);
            setError('Failed to reorder files');
        }
    };

    const renderMediaItem = (item) => {
        console.log('Rendering item:', item);
        return (
            <div 
                key={item.id}
                ref={el => {
                    if (el) {
                        el.dataset.itemId = item.id;
                        itemRefs.current[item.id] = el;
                        observerRef.current?.observe(el);
                    }
                }}
                className={`media-item ${loadedImages.has(item.id) ? 'loaded' : ''}`}
                onClick={() => !isReordering && onSelect(item)}
                draggable={isReordering}
                onDragStart={(e) => handleDragStart(e, item)}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, item)}
            >
                <div className="thumbnail-container">
                    {!loadedImages.has(item.id) && (
                        <div className="loading-placeholder" />
                    )}
                    <img 
                        src={`/api/thumbnail/${encodeURIComponent(item.path)}`}
                        alt={item.name}
                        className="media-thumbnail"
                        loading="lazy"
                        decoding="async"
                        onLoad={() => handleImageLoad(item.id)}
                    />
                    {item.type === 'video' && (
                        <div className="video-icon">
                            <FaPlay />
                        </div>
                    )}
                </div>
                <p className="media-filename">{item.name}</p>
            </div>
        );
    };

    return (
        <div className={`media-content ${viewMode} ${isReordering ? 'reordering' : ''}`}>
            {mediaItems.length > 0 ? (
                mediaItems.map(renderMediaItem)
            ) : (
                <div className="no-media">No media files found</div>
            )}
        </div>
    );
};

export default memo(MediaGallery);
